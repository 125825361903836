import React from "react"
import { Link } from "gatsby"
import wordmark from "../assets/pancho-villa-logo-white.svg"
import wordmarkHover from "../assets/pancho-villa-logo-green.svg"
import {Helmet} from "react-helmet";

export default function Header(props) {

  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);
  const [atTopOfPage, setAtTopOfPage] = React.useState(false);

  React.useEffect(() => {
    setAtTopOfPage(window.pageYOffset === 0);
    window.onscroll = function() {
      setAtTopOfPage(window.pageYOffset === 0);
    };
  });

  return (
    <div>
      <Helmet>
        <html className="has-navbar-fixed-top"/>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
      </Helmet>
      <header>
        <nav className={`navbar is-fixed-top transition ${atTopOfPage ? "at-top" : ""} ${mobileNavOpen ? "is-open" : ""}`} role="navigation" aria-label="main navigation">
          <div className="gradient-background" />
          <div className="grey-background transition" />
          <div className="navbar-brand">
            <Link className="navbar-item transition" to="/" style={{paddingTop:"10px", paddingBottom:"10px"}}>
              <div style={{position: "relative"}}>
                <img className="unhovered" src={wordmark} alt="wordmark" />
                <img className="hovered" src={wordmarkHover} alt="wordmark" />
              </div>
            </Link>

            <a role="button" className={`navbar-burger burger ${mobileNavOpen ? "is-active" : ""}`} aria-label="menu" aria-expanded="false" data-target="navbar" onClick={() => setMobileNavOpen(!mobileNavOpen)}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>

              <div className="navbar-burger-container">
                {!mobileNavOpen &&
                  <svg xmlns="http://www.w3.org/2000/svg" width="30.391" height="22.298" viewBox="0 0 30.391 22.298"><g transform="translate(179.731 83.426)"><rect className="a" width="30.391" height="3.521" transform="translate(-179.731 -83.426)"/><rect className="a" width="30.391" height="3.521" transform="translate(-179.731 -74.037)"/><rect className="a" width="30.391" height="3.521" transform="translate(-179.731 -64.648)"/></g></svg>
                }
                {mobileNavOpen &&
                  <svg xmlns="http://www.w3.org/2000/svg" width="23.979" height="23.979" viewBox="0 0 23.979 23.979"><g transform="translate(494.601 -854.159)"><rect className="a" width="30.391" height="3.521" transform="translate(-494.601 875.649) rotate(-45)"/><rect className="a" width="30.391" height="3.521" transform="translate(-492.112 854.159) rotate(45)"/></g></svg>
                }
              </div>
            </a>
          </div>

          <div className={`navbar-menu transition is-active ${mobileNavOpen ? "shown" : ""}`} >
            <div className="navbar-end">
              <Link to="/menu" className="navbar-item transition">
                Menu
              </Link>
              <Link to="/gallery" className="navbar-item transition">
                Gallery
              </Link>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}