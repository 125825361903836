import React from "react"

import fbIcon from "../assets/icons/facebook-icon-white.svg"
import igIcon from "../assets/icons/instagram-icon-white.svg"

export default function Footer() {
  return (
    <section className="section footer">
      <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
        <div className="content columns">
          <div className="column">
            <h5>Address</h5>
            <a className="transition" href="https://goo.gl/maps/MoFE5TUAXzojfuANA">361 Elgin St.<br/>Unit 8<br/>Ottawa, ON K2P 1M7</a>
          </div>
          <div className="column hours" style={{minWidth: "280px"}}>
            <h5>Hours</h5>
            <p>Tuesday 5pm-close<br/>
            Wednesday 5pm-close<br/>
            Thursday 11:30-close<br/>
            Friday 11:30-close<br/>
            Saturday 11:30-close<br/>
            Sunday 11:30-close</p>
          </div>
          <div className="column">
            <h5>Contact Info</h5>
            <a className="transition is-block
" href="tel:+6132348872">+1 613-234-8872</a>
            <a className="transition is-inline-block icon is-medium instagram-icon
" href="https://www.instagram.com/panchovillamexican/"><img src={igIcon} alt="Instagram icon" /></a>
            <a className="transition is-inline-block icon is-small facebook-icon
" href="https://www.facebook.com/profile.php?id=100052847271612"><img src={fbIcon} alt="Facebook icon" /></a>
          </div>
        </div>
      </div>
      <div>
        <p className="copyright">© 2021 Pancho Villa Restaurant. All rights reserved.</p>
      </div>
    </section>
  )
}